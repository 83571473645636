<template>
  <AppHeader />
  <Suspense>
    <template #default>
      <AppContents :content_id="content_id" />
    </template>
    <template #fallback>
      <AppLoading />
    </template>
  </Suspense>
  <AppFooter />
</template>

<script>
import AppHeader from "@/components/layouts/AppHeader";
// import AppContents from "@/components/Pages/AppContents";
import AppFooter from "@/components/layouts/AppFooter";
import AppLoading from "@/components/Spinners/AppLoading.vue";
import { defineAsyncComponent, Suspense } from "vue";
const AppContents = defineAsyncComponent(() =>
  import('@/components/Pages/AppContents')
)
export default {
  name: "Contents",
  props: {
    content_id: String,
  },
  components: {
    AppHeader,
    AppContents,
    AppFooter,
    AppLoading,
    Suspense,
  },
};
</script>

<style>
</style>